<template>
    <div class="pb-20 pt-20">
        <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-5/12">

            <div class="bg-white rounded-md shadow-md p-5">
                <div class="text-xl">
                    MASUKKAN NOMINAL DONASI
                </div>

                <div class="border-2 border-gray-200 mt-3 mb-2"></div>
                <div class="mb-2">
                    <label class="mt-2 font-bold text-lg">Rp.</label>
                    <input type="number"
                        class="mt-2 appearance-none w-full bg-gray-200 border border-gray-200 rounded h-15 shadow-sm placeholder-gray-600 focus:outline-none focus:placeholder-gray-600 focus:bg-white focus-within:text-gray-600 p-2 text-right text-xl"
                        placeholder="0" v-model="donation.amount">
                </div>


                <div class="mb-2" v-if="!donation.isLoggedIn">
                    <label class="mt-2 font-bold text-lg">Nama lengkap</label>
                    <input type="text"
                        class="mt-2 appearance-none w-full bg-gray-200 border border-gray-200 rounded h-15 shadow-sm placeholder-gray-600 focus:outline-none focus:placeholder-gray-600 focus:bg-white focus-within:text-gray-600 p-2 text-xl"
                        placeholder="" v-model="donation.name">
                </div>

                <div class="mb-2">
                    <label class="mt-2 font-bold text-lg">Do'a</label>
                    <textarea rows="3" v-model="donation.pray"
                        class="mt-2 appearance-none w-full bg-gray-200 border border-gray-200 rounded shadow-sm placeholder-gray-600 focus:outline-none focus:placeholder-gray-600 focus:bg-white focus-within:text-gray-600 p-5" placeholder="Tulis Do'a/Ucapan">

                    </textarea>
                </div>
                <div class="mb-2" v-if="!donation.isLoggedIn">
                    <label class="inline-flex items-center">
                        <input type="checkbox" class="rounded text-pink-500" v-model="donation.is_create_account" value="1" />
                        <span class="ml-2">Buatkan saya akun</span>
                  </label>
                    
                </div>

                <div class="mb-2" v-if="(!donation.isLoggedIn) && (donation.is_create_account == 1)">
                    <label class="mt-2 font-bold text-lg">Email</label>
                    <input type="email"
                        class="mt-2 appearance-none w-full bg-gray-200 border border-gray-200 rounded h-15 shadow-sm placeholder-gray-600 focus:outline-none focus:placeholder-gray-600 focus:bg-white focus-within:text-gray-600 p-2 text-xl"
                        placeholder="" v-model="donation.email">
                </div>

                <div class="mb-2" v-if="(!donation.isLoggedIn) && (donation.is_create_account == 1)">
                    <label class="mt-2 font-bold text-lg">Password</label>
                    <input type="password"
                        class="mt-2 appearance-none w-full bg-gray-200 border border-gray-200 rounded h-15 shadow-sm placeholder-gray-600 focus:outline-none focus:placeholder-gray-600 focus:bg-white focus-within:text-gray-600 p-2 text-xl"
                        placeholder="Masukan password" v-model="donation.password">
                </div>
                <div class="mb-2" v-if="(!donation.isLoggedIn) && (donation.is_create_account == 1)">
                    <label class="mt-2 font-bold text-lg">Konfirmasi Password</label>
                    <input type="password"
                        class="mt-2 appearance-none w-full bg-gray-200 border border-gray-200 rounded h-15 shadow-sm placeholder-gray-600 focus:outline-none focus:placeholder-gray-600 focus:bg-white focus-within:text-gray-600 p-2 text-xl"
                        placeholder="Masukan kembali password diatas" v-model="donation.password_confirmation">
                </div>

                <button v-if="donation.isLoggedIn" @click="storeDonation" class="mt-4 bg-yellow-500 py-2 rounded-md shadow-md text-base w-full uppercase font-bold focus:outline-none focus:bg-yellow-600">LANJUT PEMBAYARAN</button>

                <button v-else @click="storeDonation" class="mt-4 bg-yellow-500 py-2 rounded-md shadow-md text-base w-full uppercase font-bold focus:outline-none focus:bg-yellow-600">BAYAR SEKARANG</button>

            </div>

        </div>
    </div>
</template>

<script>

    //hook vue
    import { reactive,  onMounted, ref } from 'vue'
    
    //hook vuex
    import { useStore } from 'vuex'
    
    //hook vue router
    import { useRoute, useRouter } from 'vue-router'
    
    //hook Toast
    import { useToast } from "vue-toastification"

    export default {


        setup() {


            
            //validation state
            const validation = ref([])

            //store vuex
            const store = useStore()

            //route
            const route = useRoute()

            //router
            const router = useRouter()

            //toast
            const toast = useToast()

            //state donation
            const donation = reactive({
                isLoggedIn:false,
                amount: 0,                      // <-- data nilai donasi
                pray: '',                       // <-- data kata-kata/doa
                email: '',                       // <-- data email apabila belum login
                name: '',                       // <-- data nama apabila belum login
                password: '',                   // <-- data nama apabila belum login
                password_confirmation: '',      // <-- data nama apabila belum login
                is_create_account: 0,      // <-- data nama apabila belum login
                campaignSlug: route.params.slug // <-- data "slug" dari campaign
                // campaignSlug: route.params.slug // <-- data "slug" dari campaign
            })


            onMounted(() => {
                if (store.getters['auth/isLoggedIn']) {
                    donation.isLoggedIn = true
                 }
            })


            //method store donation
            function storeDonation() {
                // console.log(donation);
                // return false;

                if(donation.amount == '') {
                    toast.error('Masukan nominal donasi')
                    return false
                }
                //check minimal donasi
                if(donation.amount < 10000) {
                    toast.error('Donasi Minimal Rp. 10.000')
                    return false
                }
                if(donation.pray==''){
                    toast.error('Mohon tulis doa/ucapan');
                    return false
                }
                if(donation.isLoggedIn== false){
                    if(donation.name == ''){
                        toast.error('Nama wajib diisi')
                        return false
                    }

                    if(donation.is_create_account==true){
                        if(donation.email == ''){
                            toast.error('email wajib diisi')
                            return false
                        }
                        if(donation.password==''){
                            toast.error('Password wajib diisi')
                            return false
                        }
                        if(donation.password_confirmation==''){
                            toast.error('Konfirmasi Password wajib diisi')
                            return false
                        }
                        if(donation.password_confirmation!= donation.password){
                            toast.error('Konfirmasi Password tidak sama')
                            return false
                        }

                    }
                }
                

                store.dispatch('donation/storeDonation', donation)
                .then((res) => {

                    toast.success('Transaksi Berhasil Dibuat!')
                    if(donation.isLoggedIn){

                        router.push({name:'donation.index'})

                    }else {

                        if(res.data.donations.status == 'pending'){
                            payment(res.data.donations.snap_token)
                        }

                        router.push({name: 'home'})
                        // Set ke null
                        donation.name   = '';
                        donation.amount = 0;
                        donation.pray   = '';
                       // router.push({
                       //      name: 'donation.index', 
                       //      params: { email : donation.email }
                       //  }) 

                    }
                    

                })
                .catch(error => {
                    //show validaation message
                    validation.value = error

                    //show validation name with toast
                    if(validation.value.name) {
                        toast.error(`${validation.value.name[0]}`)
                    }

                    //show validation email with toast
                    if(validation.value.email) {
                        toast.error(`${validation.value.email[0]}`)
                    }

                    //show validation password with toast
                    if(validation.value.password) {
                        toast.error(`${validation.value.password[0]}`)
                    }
                    console.log(error)
                })
            }

            //function payment "Midtrans"
            function payment(snap_token) {

                window.snap.pay(snap_token, {

                    onSuccess: function () {
                        toast.success('Pembayarn berhasil diterima') 
                         router.push({name: 'home'})
                    },
                    onPending: function () {
                        toast.error('Pembayaran pending') 

                         router.push({name: 'home'})
                        // router.push({name: 'donation.index'})
                    },
                    onError: function () {
                        toast.error('Pembayaran gagal') 

                         router.push({name: 'home'})
                        // router.push({name: 'donation.index'})  
                    }
                })

            }

            return {

                payment,     // <-- state payment
                validation,     // <-- state validation
                // isLoggedIn,
                donation,       // <-- state donation
                storeDonation   // <-- method storeDonation
            }

        }

    }
</script>

<style>

</style>